import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import HomeSection from './components/HomeSection';
import ServicesSection from './components/ServicesSection';
import Footer from './components/Footer';
import HomeWiseDocs from './components/HomeWiseDocs';
import About from './components/About';
import Contact from './components/Contact';
import RequestAProposal from './components/RequestAProposal';
import Rental from './components/Rental';
import CommunityManagement from './components/CommunityManagement';
import FinancialServices from './components/FinancialServices';
import RealEstateServices from './components/RealEstateServices';
import NotFoundPage from './components/NotFoundPage';

import './styles/App.css';

function App() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 300) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="App">
      <a href="/" className="home-link">
        <img src="/logo.png" alt="hero" className="main-logo" />
      </a>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<HomeSection />} />
          <Route path="/services" element={<ServicesSection />} />
          <Route path="/services/community-management" element={<CommunityManagement />} />
          <Route path="/services/financial-services" element={<FinancialServices />} />
          <Route path="/services/real-estate-services" element={<RealEstateServices />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/request-a-proposal" element={<RequestAProposal />} />
          <Route path="/rental" element={<Rental />} />
          <Route path="/home-wise-docs" element={<HomeWiseDocs />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
      <Footer />
      {showTopBtn && (
        <button className="top-btn" onClick={goToTop}>
          Top
        </button>
      )}
    </div>
  );
}

export default App;
